import request from '@/utils/request'

// 查询节假日配置列表
export function listHoliday(query) {
  return request({
    url: '/config/holiday/list',
    method: 'get',
    params: query
  })
}

// 新增节假日配置
export function addHoliday(data) {
  return request({
    url: '/config/holiday/add',
    method: 'post',
    data: data
  })
}

// 删除节假日配置
export function delHoliday(data) {
  return request({
    url: '/config/holiday/remove',
    method: 'post',
    data: data
  })
}
